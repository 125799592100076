import { Dialog } from "@headlessui/react";
import Button from "components/atoms/buttons/Button";
import React from "react";
import { LoadingIcon } from "../../components/atoms/Icon";

export function VerificationModal({ isOpen, onClose, email }: { isOpen: boolean; onClose: () => void; email: string }) {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-[50]">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-full max-w-lg transform rounded-2xl bg-primary p-6 text-center shadow-xl transition-all">
          <Dialog.Title className="mb-4 text-lg font-black font-medium text-white">
            You're in! Thanks for joining.
          </Dialog.Title>
          <div className="mb-6 text-white">
            <p>
              We've sent you an email to verify your account—but no rush. For now, you can dive in, claim free programs,
              and start experiencing the benefits.
            </p>
            <br />
            <p>Just a heads-up: you'll need to verify your account before making any purchases or after 48 hours.</p>
            <br />
            <p>Enjoy exploring!</p>
          </div>
          <div className="flex justify-center">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <LoadingIcon color="white" />
              </div>
            ) : (
              <Button onClick={onClose} text="Got it" variant="magnetiqSolid" />
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
