import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Dispatch, SetStateAction, useEffect, useState, useCallback, useRef } from "react";
import GoogleIcon from "assets/Icons/google.png";
import ErrorMessage from "components/atoms/forms/ErrorMessage";
import { get, post } from "utils/request";
import { getLoginURL, getNewVerificationURL, getUserAuthURL } from "apis/user";
import toast from "react-hot-toast";
import { useUserContext } from "components/context/UserContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EyeIcon } from "@heroicons/react/20/solid";
import { useLoaderContext } from "components/context/LoaderContext";
import { copyToClipboard, PasswordSchema } from "utils/HelperFunctions";
import { GOOGLE_RECAPTCHA_KEY } from "../../constants";
import { LoadingIcon } from "../../components/atoms/Icon";
import { useVerificationModal } from "../../components/context/VerificationModalContext";
import Modal from "components/atoms/modal";
import Button from "components/atoms/buttons/Button";

type OnlyEmailType = {
  email: string;
};

type SignInType = OnlyEmailType & {
  password: string;
};

type AfterOtpType = SignInType & {
  confirmPassword: string;
  otp: string;
};

type Payload = {
  email: string;
  recaptchaToken: string;
  password?: string;
  otp?: string;
};

const EmailSchema = yup.string().email("Please enter a valid email address").required("Email is required");

const Schemas = {
  0: yup.object().shape({
    email: EmailSchema,
    password: yup.string().required("Please enter password"),
  }),
  1: yup.object().shape({
    email: EmailSchema,
  }),
  2: yup.object().shape({
    email: EmailSchema,
  }),
  3: yup.object().shape({
    email: EmailSchema,
    otp: yup.string().min(6, "Please enter complete OTP").required("Enter OTP to proceed"),
    password: PasswordSchema,
    confirmPassword: yup
      .string()
      .required("Please re-enter your password")
      .oneOf([yup.ref("password")], "Passwords doesn't match"),
  }),
};

function VerificationModal({
  isOpen,
  onClose,
  email,
  currentUrl,
}: {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  currentUrl: string;
}) {
  const [showButton, setShowButton] = useState(false);
  const lastApiCallRef = useRef<number>(0);
  const COOLDOWN_PERIOD = 60000; // 10 seconds in milliseconds

  useEffect(() => {
    if (isOpen) {
      setShowButton(false); // Reset button visibility when modal opens

      const now = Date.now();
      if (now - lastApiCallRef.current >= COOLDOWN_PERIOD) {
        lastApiCallRef.current = now;
        get(
          `${getNewVerificationURL}?email=${encodeURIComponent(
            email?.trim().toLowerCase() || "",
          )}&currentUrl=${currentUrl}`,
        ).finally(() => {
          // Show button after 2 seconds
          setTimeout(() => {
            setShowButton(true);
          }, 2000);
        });
      } else {
        // If we're in cooldown, just show the button after delay
        setTimeout(() => {
          setShowButton(true);
        }, 2000);
      }
    }
  }, [isOpen, email, currentUrl]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="rounded-2xl bg-primary p-6 text-center">
        <h3 className="mb-4 text-lg font-black font-medium text-white">
          Before we continue, please verify your email!
        </h3>
        <p className="mb-6 text-white">We just sent a link to your email to finish account setup.</p>
        <div className="flex justify-center">
          {!showButton ? (
            <div className="flex items-center justify-center">
              <LoadingIcon color="white" />
            </div>
          ) : (
            <Button onClick={onClose} text="Got it" variant="magnetiqSolid" />
          )}
        </div>
      </div>
    </Modal>
  );
}

function SignInForm({
  formType,
  setFormType,
  onLogin,
  email,
}: {
  formType: 0 | 1 | 2 | 3;
  setFormType: Dispatch<SetStateAction<0 | 1 | 2 | 3>>;
  onLogin?: () => void;
  email?: string;
}) {
  const [token, setToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { setTokenId } = useUserContext();
  const [isProcessing, setIsProcessing] = useState(false);
  // const [formType, setFormType] = useState<0 | 1 | 2 | 3>(1); // 0- signin, 1- signup, 2- reset password, 3- Otp Sent
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    formState: { errors },
    watch,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    resetField,
    reset,
    setError,
  } = useForm<AfterOtpType>({ resolver: yupResolver(Schemas[formType]) });

  const { setLoading } = useLoaderContext();
  const { openVerificationModal } = useVerificationModal();

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useEffect(() => {
    clearErrors("email");
    resetField("password");
    setIsPasswordVisible(false);
  }, [formType]);

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  // Function to remove the popup and overlay
  const removePopupAndOverlay = () => {
    const overlay = document.getElementById("overlay");
    const messageDiv = document.getElementById("iosMessageDiv");
    if (overlay) overlay.remove();
    if (messageDiv) messageDiv.remove();
  };

  // Function to disable other elements by adding an overlay
  const addOverlay = () => {
    // Create an overlay element
    const overlay = document.createElement("div");
    overlay.id = "overlay";
    overlay.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
          z-index: 9998; /* Lower than the copy link button */
          display: flex;
          justify-content: center;
          align-items: center;
        `;

    // Add the click event to close the overlay when clicking outside the popup
    overlay.addEventListener("click", (event) => {
      const messageDiv = document.getElementById("iosMessageDiv");
      // Close the popup only if the click is outside the message popup (bubble click)
      if (messageDiv && !messageDiv.contains(event.target as Node)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        removePopupAndOverlay();
      }
    });

    document.body.appendChild(overlay);
  };

  const handleIOS = () => {
    // Remove any existing message div to avoid duplication
    const existingMessageDiv = document.getElementById("iosMessageDiv");
    if (existingMessageDiv) existingMessageDiv.remove();

    // Create an overlay to disable clicks on other elements
    addOverlay();

    // Create and show a message for users
    const messageDiv = document.createElement("div");
    messageDiv.id = "iosMessageDiv";
    messageDiv.innerHTML = `
      <p>Please open this page in Safari for the best experience. Tap 'Copy Link' below and paste it in Safari.</p>
    `;
    messageDiv.className = "w-full rounded-2xl bg-primary p-7 shadow-modal md:w-[450px]";
    messageDiv.style.cssText += `
      color: white; /* White text */
      z-index: 9999; /* Ensure it stays on top of the overlay */
      position: relative; /* Keep popup centered */
      display: flex; /* Enable flexbox */
      flex-direction: column; /* Align items in a column */
      align-items: center; /* Center items horizontally */
      justify-content: center; /* Center items vertically */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `;

    // Add a button to copy the link
    const copyButton = document.createElement("button");
    copyButton.textContent = "Copy Link";
    copyButton.style.cssText = `
      padding: 10px;
      background-color: rgb(255, 180, 0); /* Darker shade for Copy Link */
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
      z-index: 9999; /* Ensure it stays on top of the overlay */
      pointer-events: auto; /* Ensure the button remains clickable */
    `;

    // Append button and message to the body or a specific container
    const overlay = document.getElementById("overlay");
    if (overlay) {
      overlay.appendChild(messageDiv);
      messageDiv.appendChild(copyButton);
    }

    // Attach the copy event to the button
    copyButton.addEventListener("click", () => {
      const currentUrl = window.location.href;
      copyToClipboard(currentUrl);
      copyButton.textContent = "Link Copied"; // Change button text
      // eslint-disable-next-line no-param-reassign
      copyButton.style.backgroundColor = "rgb(255, 220, 50)"; // Change background color when copied
    });
  };

  const handleGooleSignup = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isInAppBrowser = /Instagram|FBAN|FBAV/.test(userAgent);
    const currentUrl = window.location.href;

    if (isInAppBrowser) {
      if (/android/i.test(userAgent)) {
        // Android: Use intent to open in default browser
        window.location.href = `intent://${currentUrl.replace(
          /^https?:\/\//,
          "",
        )}#Intent;scheme=https;package=com.android.chrome;end;`;
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // iOS: Show instructions and a copy button
        handleIOS();
      }

      return;
    }
    // Proceed with OAuth flow if not in an in-app browser
    localStorage.setItem("redirectURI", window.location.pathname + window.location.search);
    setLoading(true, "Please Wait! Taking you to Google...");

    get("/api/v1/social-media/social-auth/get-auth-code", {
      processData: ({ data }) => {
        window.location.assign(data.GoogleAuthCodeEndpoint);
      },
      processError: () => {
        setLoading(false);
        toast.error("Unexpected Error, Please try Again!");
      },
    });
  };

  return (
    <div className="my-2 flex flex-col gap-6 border-b border-white border-opacity-10 pb-4 font-satoshi md:border-0">
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
      </GoogleReCaptchaProvider>
      <form className="space-y-4">
        {formType === 1 && <h2 className="text-3xl font-black text-white opacity-80">Connect with your email</h2>}
        <div>
          <label htmlFor="email" className="block text-sm font-bold leading-6 text-neutral-200">
            Email
          </label>
          <div className="mt-1">
            <input
              {...register("email")}
              placeholder={formType === 2 ? "Enter registered email" : "Enter your email"}
              id="email"
              name="email"
              type="email"
              required
              disabled={formType === 3}
              className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent disabled:cursor-not-allowed disabled:bg-opacity-20 disabled:ring-opacity-25 sm:text-sm sm:leading-6"
            />
          </div>
          <ErrorMessage errorMessage={errors?.email?.message} />
        </div>

        {formType === 0 && (
          <div>
            <label htmlFor="password" className="block text-sm font-bold leading-6 text-neutral-200">
              Password
            </label>
            <div className="relative mt-1">
              <input
                {...register("password")}
                placeholder="Enter password"
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                required
                className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute right-[3%] top-[35%] opacity-70 hover:opacity-95"
              >
                <EyeIcon className="h-5 w-5 text-white" />
              </button>
            </div>
            <ErrorMessage errorMessage={errors?.password?.message} />
          </div>
        )}

        {formType === 0 && (
          <div className="text-right text-sm">
            <button
              type="button"
              onClick={() => setFormType(2)}
              className="font-semibold text-accent text-opacity-90 underline-offset-4 hover:text-opacity-100 hover:underline"
            >
              Forgot password?
            </button>
          </div>
        )}

        <div className="space-y-2">
          <button
            disabled={isProcessing}
            onClick={handleSubmit((data) => {
              setIsProcessing(true);
              setRefreshReCaptcha(!refreshReCaptcha);
              let apiEndpoint = "";
              const payload: Payload = { email: data.email, recaptchaToken: token };

              switch (formType) {
                case 0:
                  payload.password = data.password;
                  apiEndpoint = getLoginURL;
                  break;
                case 1:
                  const sanitizedEmail = data.email.trim().toLowerCase();
                  post(
                    `${getUserAuthURL}`,
                    {
                      email: sanitizedEmail,
                      recaptchaToken: token,
                      redirectUrl: window.location.href,
                    },
                    {
                      processData: (response) => {
                        if (response.data.verified) {
                          setFormType(0);
                        } else if (response.data.can_login) {
                          setTokenId(response.data.token);
                          toast.success("Great to have you! Check your email to verify your account.");
                          const isBuyNow = new URLSearchParams(window.location.search).get("is_buy_now");
                          if (!isBuyNow) {
                            openVerificationModal(data.email);
                          }
                          if (onLogin) {
                            onLogin();
                          }
                        } else if (response.data.user_exists) {
                          setFormType(0); // User exists, switch to sign in form
                          toast.error("Account already exists. Please sign in.");
                        } else {
                          setShowVerificationModal(true);
                        }
                      },
                      processError: (err) => {
                        console.error("User auth error:", err);
                        setIsProcessing(false);
                        setError("email", { message: err });
                      },
                    },
                  );
                  setIsProcessing(false);
                  return;
                case 2:
                  const trimmedEmail = data.email.trim().toLowerCase();
                  post(
                    `${getUserAuthURL}`,
                    {
                      email: trimmedEmail,
                      type: "reset_password",
                      recaptchaToken: token,
                      redirectUrl: window.location.href,
                    },
                    {
                      processData: () => {
                        toast.success("Password reset email sent to your email.");
                        setFormType(0);
                      },
                      processError: (err) => {
                        console.error("User auth error:", err);
                        setIsProcessing(false);
                        setError("email", { message: err });
                      },
                    },
                  );
                  setIsProcessing(false);
                  return;
                default:
                  throw new Error("Unexpected Error!");
              }

              post(apiEndpoint, payload, {
                processData: ({ data }) => {
                  setIsProcessing(false);

                  if (data.success === false) {
                    toast.error("We are sorry, your request could not be processed right now. Please reload the page.");
                    return;
                  }

                  if (formType === 0) {
                    setTokenId(data.token);
                    if (onLogin) {
                      onLogin();
                    }
                  }
                },
                processError: (err) => {
                  setIsProcessing(false);
                  let errField: "password" | "email";
                  switch (formType) {
                    case 0:
                      errField = "password";
                      break;
                    default:
                      errField = "email";
                  }
                  setError(errField, { message: err });
                },
              });
            })}
            type="submit"
            className="flex w-full justify-center rounded-md  bg-accent bg-opacity-90 px-3 py-3 text-sm font-semibold leading-6 text-primary shadow-sm transition hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent active:scale-90 disabled:cursor-wait disabled:opacity-70"
          >
            {isProcessing ? (
              <div className="flex h-6 w-full items-center justify-center">
                <LoadingIcon color="black" />
              </div>
            ) : formType === 0 ? (
              "Sign In"
            ) : formType === 3 ? (
              "Submit"
            ) : (
              "Continue"
            )}
          </button>
          {formType === 3 && (
            <div className="flex flex-col text-left text-xxs text-gray-500">
              <div className=""> This site is protected by reCAPTCHA.</div>
              <div>
                {" "}
                Google
                <a className="text-accent" href="https://policies.google.com/privacy">
                  {" "}
                  Privacy Policy{" "}
                </a>
                and
                <a className="text-accent" href="https://policies.google.com/terms">
                  {" "}
                  Terms of Service{" "}
                </a>
                apply.
              </div>
            </div>
          )}

          {(formType === 0 || formType === 1) && (
            <>
              <h4 className="text-center text-xxs font-medium text-white">OR</h4>
              <button
                onClick={handleGooleSignup}
                type="button"
                className="flex w-full items-center justify-center gap-2 rounded-md bg-white bg-opacity-90 p-2 font-medium text-primary shadow-sm transition hover:bg-opacity-100 active:scale-90"
              >
                <img src={GoogleIcon} alt="Google Icon" className="h-8" />
                <div>Continue With Google</div>
              </button>

              <div className="flex flex-col text-left text-xxs text-gray-500">
                <div> This site is protected by reCAPTCHA.</div>
                <div>
                  {" "}
                  Google
                  <a className="text-accent" href="https://policies.google.com/privacy">
                    {" "}
                    Privacy Policy{" "}
                  </a>
                  and
                  <a className="text-accent" href="https://policies.google.com/terms">
                    {" "}
                    Terms of Service{" "}
                  </a>
                  apply.
                </div>
              </div>
            </>
          )}
        </div>
      </form>
      <VerificationModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        email={watch("email") || ""}
        currentUrl={window.location.href}
      />
    </div>
  );
}

export default SignInForm;
