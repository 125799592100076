import React, { createContext, useContext, useState } from "react";

type VerificationModalContextType = {
  showVerificationModal: boolean;
  verificationEmail: string;
  openVerificationModal: (email: string) => void;
  closeVerificationModal: () => void;
};

const VerificationModalContext = createContext<VerificationModalContextType | undefined>(undefined);

export function VerificationModalProvider({ children }: { children: React.ReactNode }) {
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState("");

  const openVerificationModal = (email: string) => {
    setVerificationEmail(email);
    setShowVerificationModal(true);
  };

  const closeVerificationModal = () => {
    setShowVerificationModal(false);
  };

  return (
    <VerificationModalContext.Provider
      value={{
        showVerificationModal,
        verificationEmail,
        openVerificationModal,
        closeVerificationModal,
      }}
    >
      {children}
    </VerificationModalContext.Provider>
  );
}

export const useVerificationModal = () => {
  const context = useContext(VerificationModalContext);
  if (context === undefined) {
    throw new Error("useVerificationModal must be used within a VerificationModalProvider");
  }
  return context;
};
