import { useVerificationModal } from "components/context/VerificationModalContext";
import { VerificationModal } from "components/molecules/VerificationModal";

const VerificationModalPage = () => {
  const { showVerificationModal, verificationEmail, openVerificationModal, closeVerificationModal } =
    useVerificationModal();
  return (
    <VerificationModal isOpen={showVerificationModal} onClose={closeVerificationModal} email={verificationEmail} />
  );
};

export default VerificationModalPage;
