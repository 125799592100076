import moment from "moment";
import toast from "react-hot-toast";

export const queryStringParser = (query: string) => {
  const searchParams = new URLSearchParams(query);
  const searchParamsEntries = [...(searchParams.entries() as any)];
  const queryStringParsedObj = searchParamsEntries.reduce((prevObject, currentEntry) => {
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    prevObject[currentEntry[0]] = currentEntry[1];
    return prevObject;
  }, {});
  return queryStringParsedObj || {};
};

export const getTzOffsetFromUTC = () => moment().utcOffset() * 60 * 1000;
/**
 *
 * @param {number} timestamp
 */
export const utcToLocalDate = (timestamp: number) => timestamp - getTzOffsetFromUTC();

export const downloadOnClick = (url: string, CustomFileName?: string) => {
  const fileName = url.split("/").pop() || "file";
  const anchor = document.createElement("a");
  anchor.href = url;

  anchor.download = CustomFileName ? `${CustomFileName}.csv` : fileName;
  anchor.target = "_blank";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const isHTML = (str: string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
};

export const handleNotOwnedMementoDescriptionLinkClick = (
  e: React.MouseEvent,
  isGatedContent: boolean,
  isMagnetOwnedByUser: boolean,
) => {
  if (isGatedContent && !isMagnetOwnedByUser) {
    e.preventDefault();
    toast("Please purchase this magnet to access this link", {
      duration: 3000,
      position: "top-center",
      icon: "ⓘ", // Using the info icon as a Unicode character
      style: {
        borderRadius: "10px",
        background: "#333", // Dark background for dark mode
        color: "#fff", // White text
      },
      iconTheme: {
        primary: "#FFEB3B", // Yellow color for the icon
        secondary: "#333", // Dark background for the icon
      },
    });
  }
};

export const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
