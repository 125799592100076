import ErrorMessage from "../../atoms/forms/ErrorMessage";
import Label from "../../atoms/forms/Label";

type InputFieldBaseProps = {
  label: string;
  id: string;
  placeHolder: string;
  defaultValue?: string;
  errorMessage: string | undefined;
  additionalClasses?: string;
  type?: "number" | "text";
  register: any;
  labelClasses?: string;
  isDisabled?: boolean;
  errorMessageClasses?: string;
  handleOnKeyDown?: (e: KeyboardEvent) => void;
  handleOnBlur?: (e: any) => void;
  isRequired?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
};

function InputFieldWithLabel({
  label,
  id,
  additionalClasses,
  placeHolder,
  defaultValue = "",
  errorMessage,
  register,
  type = "text",
  labelClasses = "",
  isDisabled,
  handleOnKeyDown,
  handleOnBlur,
  errorMessageClasses,
  isRequired,
  onChange,
  onPaste,
}: // bindedRegister,
InputFieldBaseProps) {
  return (
    <div className="rounded-md ">
      <Label labelClasses={labelClasses} htmlFor={id} label={label} />
      <input
        id={id}
        name={id}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(id, {
          onChange: onChange,
          onBlur: handleOnBlur,
          required: isRequired,
          onPaste: onPaste,
        })}
        autoComplete="off"
        type={type}
        className={`spin-button-none block w-full rounded-ll border-1 border-white bg-transparent p-3.5 pr-10
        font-satoshi font-450 text-white placeholder:text-white placeholder:opacity-70 focus:border-white
        focus:outline-none focus:ring-gray-200 disabled:cursor-not-allowed 
        disabled:border-zinc-500 disabled:bg-zinc-500  disabled:bg-opacity-50 sm:text-xs ${additionalClasses}`}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        aria-invalid="true"
        disabled={isDisabled}
        onKeyDown={handleOnKeyDown}
        onChange={onChange}
        onPaste={onPaste}
      />
      <ErrorMessage errorMessage={errorMessage} errorMessageClasses={errorMessageClasses} />
    </div>
  );
}
export default InputFieldWithLabel;

InputFieldWithLabel.defaultProps = {
  additionalClasses: "",
  handleOnKeyDown: () => {},
};
