/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
import { useUserContext } from "components/context/UserContext";
import { isMediaOfTypeAudioOrVideo, isMediaOfTypeDocument, isMediaOfTypeImage } from "utils/helpers";
import Modal from "components/atoms/modal";
import { BrightBoxIcon, CloseIcon } from "components/atoms/Icon";
import VideoPreviewer from "components/atoms/VideoPreviewer";
import BlurredImage from "components/atoms/BlurredImage";
import Button from "components/atoms/buttons/Button";
import { CheckCircleIcon, ExclamationTriangleIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/solid";
import { useRouter } from "components/hooks";
// import { useMemo } from "react";
import { downloadOnClick, handleNotOwnedMementoDescriptionLinkClick, isHTML } from "utils";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

function ViewMementoModal({ mementoId, onClose, data }: { mementoId: string; onClose: any; data: any }) {
  const { isMagnetInUserInventory } = useUserContext();
  // const router = useRouter();
  const isMagnetOwnedByUser = isMagnetInUserInventory(data.magnet_id);
  const isMementoOwnedUser = isMagnetInUserInventory(mementoId);

  const isFileAudioOrVideo = isMediaOfTypeAudioOrVideo(data.media_type || data.media_url || "");
  const isFileImage = isMediaOfTypeImage(data.media_type || data.media_url || "");
  const isFileDocument = isMediaOfTypeDocument(data.media_type || data.media_url || "");
  const isGatedContent = data.is_gated;
  const [showThumbnail, setShowThumbnail] = useState(true);

  const [isFlipped, setIsFlipped] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  // Ensure thumbnail is always shown
  // const thumbnailUrl = data.thumbnail_url || "default-thumbnail.jpg"; // Use a default thumbnail if none is provided

  // Update the useEffect to trigger the animation for both owned and non-owned magnets
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (mementoId) {
      if (isFileAudioOrVideo && isMagnetOwnedByUser) {
        // First flip the card
        timer = setTimeout(() => {
          setIsFlipped(true);
        }, 500);

        // Then show the video after the flip
        const videoTimer = setTimeout(() => {
          setShowThumbnail(false);
          setShowVideo(true);
        }, 1500);

        return () => {
          clearTimeout(timer);
          clearTimeout(videoTimer);
        };
      }
      // For non-video content or non-owned magnets, just do the flip
      timer = setTimeout(() => {
        setIsFlipped(true);
      }, 500);
    }

    return () => {
      if (timer) clearTimeout(timer);
      setIsFlipped(false);
      setShowThumbnail(true);
      setShowVideo(false);
    };
  }, [isFileAudioOrVideo, mementoId, isMagnetOwnedByUser]);

  const bottomBarContent = (() => {
    if (isMagnetOwnedByUser && isMementoOwnedUser) {
      return (
        <>
          <CheckCircleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: You have access to this Content
          </p>
        </>
      );
    }

    // User doesn't own the magnet.
    if (!isMagnetOwnedByUser) {
      if (data.auto_drop_on_purchase)
        return (
          <>
            <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
            <p className="font-sora text-xxs font-semibold text-white opacity-50">Buy the Magnet now to get access</p>
          </>
        );

      return (
        <>
          <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: Only owners of the memento can view this
          </p>
        </>
      );
    }
    // User Owns the Magnet
    if (isMagnetOwnedByUser) {
      // Since autodrop is set to false. user will never get the memento.
      if (!data.auto_drop_on_purchase) {
        return (
          <>
            <LockClosedIcon color="white" className="h-4 w-4 opacity-50" />
            <p className="font-sora text-xxs font-semibold text-white opacity-50">
              Exclusive Gated Content: Only owners of the memento can view this
            </p>
          </>
        );
      }
      // Allowed Airdrop Purchase.
      return (
        <>
          <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: You need to claim this memento before you can view it
          </p>
        </>
      );
    }

    return null;
  })();

  // const buttonText = useMemo(() => {
  //   if (isGatedContent && !data.auto_drop_on_purchase) {
  //     return "ALREADY DROPPED";
  //   }
  //   return "BUY THE MAGNET";
  // }, [data]);

  // const redirectToMagnetPage = () => {
  //   router.go(getMagnetPageLink(data.brand_id, data.magnet_id));
  //   onClose();
  // };

  return (
    <Modal open={Boolean(mementoId)} onClose={onClose} closeOnOutsideClick>
      <div className="mx-auto w-[95vw] max-w-[550px] space-y-5 rounded-2xl bg-primary p-5 py-7 shadow-modal">
        {/* Section 1  */}
        <div className="flex justify-between gap-4">
          <div className="flex space-x-3 overflow-x-clip ">
            <Link to={`/brand/${data.brand_id}/magnet/${data.magnet_id}`} title={data.magnet_name} onClick={onClose}>
              <img
                className="aspect-1 h-16 rounded-lg border border-white object-cover"
                src={data.magnet_image_url ?? undefined}
                alt={data.magnet_image_url}
              />
            </Link>
            <div className="flex flex-col overflow-x-clip font-satoshi text-white">
              <span className="text-lg font-semibold leading-tight">{data.magnet_name}</span>
              <span className="opacity-70">{data.brand_name}</span>
              {isMagnetOwnedByUser && (
                <span className="inline-flex items-center gap-1 rounded-xl bg-accent bg-opacity-10 px-2">
                  <BrightBoxIcon color="text-accent" className="h-3 w-3  md:h-4 md:w-4" />
                  <p className="font-satoshi text-[10px] font-bold uppercase text-accent">You own this Magnet</p>
                </span>
              )}
            </div>
          </div>
          <button type="button" className="self-start p-1" onClick={onClose}>
            <CloseIcon className="h-5 w-5 text-white" />
          </button>
        </div>
        {/* Section 2 */}
        <div className="mx-auto flex flex-col items-center font-satoshi text-white">
          <div className="grid w-full place-items-stretch">
            <div className="perspective relative h-full w-full">
              <div className="relative">
                {/* Media content */}
                {
                  <>
                    {isMagnetOwnedByUser || !isGatedContent ? (
                      // First div - For owned magnets
                      <div className="relative  mx-auto h-[400px] w-10/12">
                        <div className="absolute -right-11 -top-3 z-50 rounded-full bg-accent p-1.5">
                          {isMagnetOwnedByUser ? (
                            <LockOpenIcon className="h-5 w-5" color="black" />
                          ) : (
                            <LockClosedIcon className="h-5 w-5" color="black" />
                          )}
                        </div>
                        <div
                          className={`preserve-3d relative h-full transition-transform duration-700 ${
                            data.media_url && isFlipped ? "rotate-y-180" : ""
                          }`}
                        >
                          {/* Front side (thumbnail) */}
                          <div
                            className={`backface-hidden absolute h-full w-full ${
                              data.media_url && isFlipped ? "invisible" : "visible"
                            }`}
                          >
                            <img src={data.thumbnail_url} alt="thumbnail" className="h-full w-full rounded-2xl" />
                          </div>

                          {/* Back side (media content) */}
                          <div
                            className={`backface-hidden rotate-y-180 absolute inset-0 flex items-center justify-center ${
                              isFlipped ? "visible" : "invisible"
                            }`}
                          >
                            {isFileAudioOrVideo ? (
                              <div className="flex h-full w-full items-center justify-center">
                                <VideoPreviewer
                                  videoPlayerId="memento_video_viewer"
                                  src={data.media_url}
                                  thumbnailUrl={data.thumbnail_url}
                                  videoContainerAdditionalClasses="rounded-2xl"
                                />
                              </div>
                            ) : (
                              <img
                                src={isFileImage ? data.media_url : data.thumbnail_url}
                                alt={data.name}
                                className="absolute h-full w-full rounded-2xl"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      // Second div - For non-owned magnets
                      <div className="relative mx-auto h-[400px] w-10/12">
                        <div className="absolute -right-11 -top-3 z-50 rounded-full bg-accent p-1.5">
                          <LockClosedIcon className="h-5 w-5" color="black" />
                        </div>
                        <div className="relative h-full w-full">
                          {/* Thumbnail with conditional flip effect */}
                          <img
                            src={data.thumbnail_url}
                            alt="thumbnail"
                            className={`h-full w-full rounded-2xl ${data.media_url ? "animate-flip" : ""}`}
                            style={{ zIndex: 3 }}
                          />

                          {/* Media content underneath */}
                          <div className="absolute inset-0 grid place-items-stretch" style={{ zIndex: -1 }}>
                            {data.media_url &&
                              (isFileAudioOrVideo ? (
                                <div className="h-full w-full">
                                  <VideoPreviewer
                                    videoPlayerId="memento_video_viewer"
                                    src={data.media_url}
                                    thumbnailUrl={data.thumbnail_url}
                                    videoContainerAdditionalClasses="h-full w-full rounded-2xl"
                                    autoPlay={false}
                                    disablePlayButton
                                  />
                                </div>
                              ) : (
                                <img
                                  src={isFileImage ? data.media_url : data.thumbnail_url}
                                  alt={data.name}
                                  className="absolute h-full w-full rounded-2xl"
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
          {/* Media content container - Using w-10/12 to match thumbnail */}
          <div className="w-10/12">
            {data.secondary_media_url && (
              <div title={isGatedContent && !isMementoOwnedUser ? "You dont have access to this content" : ""}>
                <button
                  onClick={() => downloadOnClick(data.secondary_media_url)}
                  disabled={isGatedContent && !isMementoOwnedUser}
                  type="button"
                  className="mt-3 block rounded-full bg-accent bg-opacity-90 px-4 py-2.5 text-xxs font-semibold text-primary shadow-sm hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed disabled:opacity-40"
                >
                  View Content
                </button>
              </div>
            )}
            {/* {!data.secondary_media_url && (
              <div title={isGatedContent && !isMementoOwnedUser ? "You dont have access to this content" : ""}>
                <button
                  onClick={() => downloadOnClick(data.media_url)}
                  disabled={isGatedContent && !isMementoOwnedUser}
                  type="button"
                  className="mt-3 block rounded-full bg-accent bg-opacity-90 px-4 py-2.5 text-xxs font-semibold text-primary shadow-sm hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed disabled:opacity-40"
                >
                  View Content
                </button>
              </div>
            )} */}
            <span className="mt-6 block w-full text-left text-lg font-450 leading-tight">{data.name}</span>
            {isFileDocument && (
              <div title={isGatedContent && !isMementoOwnedUser ? "You dont have access to this content" : ""}>
                <button
                  onClick={() => downloadOnClick(data.media_url)}
                  disabled={isGatedContent && !isMementoOwnedUser}
                  type="button"
                  className="mt-3 block rounded-full bg-accent bg-opacity-90 px-4 py-2.5 text-xxs font-semibold text-primary shadow-sm hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed disabled:opacity-40"
                >
                  View Content
                </button>
              </div>
            )}

            <div className="secondary-scrollbar quill-content my-2 h-24 w-full overflow-scroll overflow-x-hidden text-left text-white">
              {!isHTML(data?.description) ? (
                <p>{data.description}</p>
              ) : (
                <div
                  onClick={(e) => {
                    const target = e.target as HTMLElement;
                    if (target.tagName === "A") {
                      handleNotOwnedMementoDescriptionLinkClick(e, isGatedContent, isMagnetOwnedByUser);
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<div class="ql-container ql-bubble" style="overflow: visible; margin: 0; padding: 0;">
                          <div class="ql-editor ql-snow" style="overflow: visible; margin: 0; padding: 0;">
                            ${data.description}
                          </div>
                        </div>`,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isGatedContent && (
        <div className="mx-auto -mt-6 inline-flex w-[95vw] max-w-[550px] items-center justify-center gap-2 rounded-b-lg bg-[#102D4E] p-2">
          {bottomBarContent}
        </div>
      )}
      <style>{`
        .perspective {
          perspective: 1000px;
        }
        
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
        
        .visible {
          visibility: visible;
        }
        
        .invisible {
          visibility: hidden;
        }

        .hover-lift {
          transform: translateY(-8px);
          box-shadow: 0 8px 20px rgba(0,0,0,0.4);
        }

        .hover-lift:hover {
          transform: translateY(-12px);
          box-shadow: 0 12px 25px rgba(0,0,0,0.5);
        }
        
        img {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }

        .preserve-3d {
          transform-style: preserve-3d;
        }
        
        .backface-hidden {
          backface-visibility: hidden;
        }
        
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
        
        .visible {
          visibility: visible;
        }
        
        .invisible {
          visibility: hidden;
        }
        
        .animate-flip {
          animation: flip 2s infinite;
        }
        
        @keyframes flip {
          0%, 100% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(60deg);
          }
        }

        .animate-flip-horizontal {
          animation: flipHorizontal 2s linear infinite;
          transform-style: preserve-3d;
        }
        
        @keyframes flipHorizontal {
          0% {
            transform: rotateY(0);
          }
          100% {
            transform: rotateY(360deg);
          }
        }

        @keyframes flip {
          0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(60deg);
          }
          100% {
            transform: rotateY(0deg);
          }
        }
        
        .animate-flip {
          animation: flip 2s infinite ease-in-out;
        }
      `}</style>
    </Modal>
  );
}

export default ViewMementoModal;
