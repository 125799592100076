import { matchRoutes, useLocation } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import BrandSidebarContainer from "components/molecules/sidebar/BrandSidebarContainer";
import UserSidebarContainer from "components/organisms/user/UserSidebarContainer";
import { UserContext, useUserContext } from "components/context/UserContext";
import { useDeviceType, useRouter } from "components/hooks";
import OnlyAvailableOnDesktop from "pages/OnlyAvailbleOnDesktop";
import { ROUTES } from "../../../constants";
import { VerificationBanner } from "components/atoms/VerificationBanner";

const brandRoutes = [
  {
    path: ROUTES.BRAND_DASHBOARD,
  },
  {
    path: ROUTES.BRAND_ANALYTICS,
  },
  {
    path: ROUTES.BRAND_SETTINGS_ROUTE,
  },
  {
    path: ROUTES.CREATE_PUBLIC_MAGNET_ROUTE,
  },
  {
    path: ROUTES.CREATE_CONTROLLED_MAGNET_ROUTE,
  },
  {
    path: ROUTES.CREATE_SUBSCRIPTION_MAGNET_ROUTE,
  },
  {
    path: `${ROUTES.EDIT_MAGNET}/:magnetId`,
  },
  {
    path: ROUTES.AIRDROP_MEMENTO,
  },
  {
    path: ROUTES.EDIT_AIRDROP_MEMENTO,
  },
  {
    path: ROUTES.CREATE_POLL,
  },
  {
    path: ROUTES.EDIT_POLL,
  },
  {
    path: ROUTES.MERCH_DROP,
  },
  {
    path: ROUTES.EDIT_MERCH_DROP,
  },
  {
    path: ROUTES.CREATE_CLAIM_CODE,
  },
  {
    path: ROUTES.EDIT_CLAIM_CODE,
  },
  {
    path: ROUTES.CREATE_SHARE_REQUEST,
  },
  {
    path: ROUTES.EDIT_SHARE_REQUEST,
  },
];

const userRoutes = [
  {
    path: ROUTES.USER_PROFILE_PAGE,
  },
  {
    path: ROUTES.BRANDS_DISCOVER,
  },
  {
    path: ROUTES.MAGNET_PAGE,
  },
  {
    path: `${ROUTES.BRAND_PUBLIC_PROFILE}/:brandId`,
  },
];

function SidebarLayout({
  children,
  isSidebarCustomized = false,
}: {
  children: ReactNode | ReactNode[];
  isSidebarCustomized?: boolean;
}) {
  const { isUserLoggedIn, userDetails } = useUserContext();
  const currentLocation = useLocation();
  const router = useRouter();

  const isBrandSidebarRoute = matchRoutes(brandRoutes, currentLocation);

  const { isMobile } = useDeviceType(767);

  // Check if need to render user sidebar.
  const isUserSidebarRoute =
    isUserLoggedIn &&
    (matchRoutes(userRoutes, currentLocation) ||
      // User Sidebar is not visible on profile settings page on desktop
      // Only visible on mobile for easier navigation.
      (isMobile && currentLocation.pathname.includes(ROUTES.PROFILE_SETTING)));

  const isBrandPublicProfile = currentLocation.pathname.includes(ROUTES.BRAND_PUBLIC_PROFILE);

  const isMagnetPage = matchRoutes([{ path: ROUTES.MAGNET_PAGE }], currentLocation);

  // INFO: If user is logged in and in url there is redirectURI, then user will redirect to that particular route
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectURI = queryParams.get("redirectURI");

  // const showVerificationBanner = userDetails?.magnetiq_program_status === 0;
  const showVerificationBanner = false;

  useEffect(() => {
    if (isUserLoggedIn && redirectURI) {
      router.go(redirectURI);
    }
  }, [isUserLoggedIn]);

  return isMobile && isBrandSidebarRoute ? (
    <OnlyAvailableOnDesktop />
  ) : (
    <div>
      {/* Verification Banner only to show on small screen and for desktop we rendering this component on Navbar */}
      {showVerificationBanner && <VerificationBanner className="m-4 md:hidden" />}
      <div className={`absolute  ${showVerificationBanner ? "top-32" : "top-16"} flex h-screen w-full md:top-0`}>
        <div className="sticky shrink">
          {isBrandSidebarRoute && <BrandSidebarContainer />}

          {isUserSidebarRoute && <UserSidebarContainer isSidebarCustomized={isSidebarCustomized} />}
        </div>

        {/* Children Container */}
        <div
          // className="mt-4 grow overflow-auto px-3 pt-1 md:mt-20 md:px-5"
          className={`secondary-scrollbar grow overflow-auto ${
            // eslint-disable-next-line no-nested-ternary
            isBrandPublicProfile
              ? "mt-1 px-0 md:mt-[68px]"
              : isMagnetPage
              ? "px-0 md:mt-[68px]"
              : "mt-4 px-3 pt-1 md:mt-20 md:px-5"
          }`}
          // className={clsx(
          //   isBrandSidebarRoute && "ml-[18rem] 2xl:ml-[21.5rem]",
          //   // User Sidebar Layout
          //   isUserSidebarRoute ? (isUserSidebarCollapsed ? "ml-20" : "md:ml-72 xl:ml-80 2xl:ml-96") : "",
          //   "relative",
          // )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
export default SidebarLayout;
